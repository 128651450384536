import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import GatsbyImage from 'gatsby-image';
import classnames from 'classnames';
import GatsbyLink from '../../GatsbyLink/GatsbyLink';
import { customPropTypes, useTransitionDelay } from '../../../util';
import { STAGGER } from '../../../util/motion-manager/constant';
import './CardGridItem.scss';

function CardGridItem({ data: { logo, link, title, description }, showBodyContent, index }) {
  const transitionDelay = useTransitionDelay(STAGGER * (index + 1));
  const Tag = link?.url ? GatsbyLink : 'div';
  const others = link?.url ? { to: link.url, title: link.title, target: link.target } : {};

  return (
    <Tag {...others} className={classnames('CardGridItem', { showBodyContent })} style={transitionDelay}>
      <div className="head">
        <div className="logo-container gray">
          <GatsbyImage
            className="logo"
            fluid={logo?.localFile.childImageSharp.fluid}
            alt={logo?.altText}
            imgStyle={{ objectFit: 'contain', objectPosition: 'center' }}
          />
        </div>
      </div>
      {showBodyContent && (
        <div className="body">
          {title && <div className="body-title" dangerouslySetInnerHTML={{ __html: title }} />}
          {description && <div className="body-text" dangerouslySetInnerHTML={{ __html: description }} />}
        </div>
      )}
    </Tag>
  );
}

CardGridItem.propTypes = checkExtraProps({
  data: PropTypes.shape({
    logo: customPropTypes.image,
  }).isRequired,
  index: PropTypes.number,
});

CardGridItem.defaultProps = {};

export default memo(CardGridItem);
